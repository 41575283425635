:root {
  --primary: #00559e;
  --primary-light: #e5eef5;
  --accent: #000000;
  --accent-light: #3e4659;
  --background: #f2f3f7;
  --background-light: #f9f9fb;
  --header: #69778c;
  --success: #008c43;
  --danger: #f44336;
  --pending: #f47100;
  --warn: #f9aa3e;
  --info: #6058e3;
  --warn2: #c18d70;
  --divider: #dce0eb;
  --border: #e2e4f0;
  --url: #30a0f8;
  --placeholder: rgba(0, 0, 0, 0.37);
  --warn3:#feeceb;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}
