/*  Flex  */
.row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.row-start-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.row-center-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row-spaced-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-spaced-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.column-start-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.column-start-stretch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

/*  Container Classes  */
.v-scroll {
  height: 100%;
  overflow-y: scroll;
}

.word-break {
  word-break: break-all;
}

.relative {
  position: relative;
}

.priority-row {
  background: var(--warn3);
  border: 2px solid var(--danger) !important;
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.invalid-number-message {
  left: 4px !important;
  top: 51px !important;
}

.flex-1 {
  flex: 1;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.scroll-smooth {
  scroll-behavior: smooth;
}