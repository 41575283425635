.styledBtn .MuiButton-label {
  line-height: normal;
}

.icon {
  width: 100%;
  min-width: 1.25rem;
  margin-right: 0.5rem !important;
}

.marginRight {
  margin-right: 1rem !important;
}

.loader {
  margin-left: 1rem;
}
