.sm .MuiPaper-root {
    max-width: 700px !important;
}

.xs .MuiPaper-root {
    max-width: 400px !important;
}

.hide-bg {
    background-color: rgba(255, 255, 255, 1);
}

.image-modal .MuiDialog-paper {
    height: 60vh;
}

.table-border table {
    border-collapse: collapse !important;
}

.MuiMenuItem-root.Mui-selected,
.MuiMenuItem-root:hover {
    background-color: var(--background) !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding: 0.875rem 2rem 0.875rem 0.875rem !important;
}

.MuiPaginationItem-page:focus {
    background-color: rgba(0, 85, 158, 0.1);
}

.MuiPaginationItem-page.Mui-selected {
    color: white !important;
    background-color: var(--primary) !important;
}

.MuiTab-root.MuiButtonBase-root {
    min-width: 130px;
}

.MuiTab-root span.MuiTab-wrapper {
    text-transform: none;
    font-size: 0.875rem !important;
    font-weight: normal !important;
}

.MuiTab-root.Mui-selected span.MuiTab-wrapper {
    font-weight: bold !important;
}

.rdrDateDisplayWrapper {
    background-color: white !important;
}

.rdrDefinedRangesWrapper {
    background-color: var(--background) !important;
    margin-bottom: -6em;
}

button.rdrStaticRange {
    background-color: transparent;
}

.custom-pagination .MuiTablePagination-toolbar {
    width: 100%;
}

.custom-pagination .MuiTablePagination-selectRoot {
    border: 1px solid var(--header) !important;
    border-radius: 0.25rem !important;
    padding-bottom: 0 !important;
}

.custom-pagination .MuiTablePagination-spacer {
    display: none;
}

.custom-pagination .MuiTablePagination-selectRoot + .MuiTablePagination-caption {
    flex: 1;
    text-align: end;
}

.mr-1 {
    margin-right: 0.75rem;
}

.ml-1 {
    margin-left: 0.75rem;
}

.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 5px) !important;
}

.MuiSelect-select.Mui-disabled {
    cursor: default;
    color: var(--header);
    background-color: var(--background-light);
}

.MuiButton-contained {
    box-shadow: none !important;
}

.MuiButton-root {
    text-transform: unset !important;
}

.MuiRadio-root + .MuiFormControlLabel-label {
    color: var(--header);
}

.MuiRadio-root.Mui-checked + .MuiFormControlLabel-label {
    color: var(--accent);
    font-weight: bold;
}
