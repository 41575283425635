.customersUploadContainer {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.icon {
    margin-bottom: 1.25rem;
}

.title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
}

.description {
    color: var(--accent-light);
    text-align: center;
    margin-bottom: 0.75rem;
}