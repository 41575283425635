.dialog {
    position: relative;
}

.closeBtn {
    position: absolute !important;
    top: 0.5rem;
    right: 0.5rem;
}
.padding {
    padding: 1rem !important;
}
.noPadding {
    padding: 0 !important;
}
.noTopPadding{
padding-top: 0.2rem !important;
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}
.message {
    margin-left: 1.5rem;
}

